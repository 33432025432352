<template>
	<Modal class-name="my-modal" v-model="show" title="参数设置" width="800" :scrollable="true" @on-cancel="show = false">
		<Form ref="formValidate" :model="formData" :rules="ruleValidate" label-position="right" :label-width="140" style="height: calc(100vh - 340px); overflow-y: auto;">
			<Card dis-hover class="mar-b24" v-for="(item,index) in formItem" :key="index" :title="item.title">
				<div v-for="(item2,index2) in item.children" :key="index2">
					<template v-if="item.title == '联系人设置'">
						<template v-if="index2 == 'PHL'">
							<div v-for="(item3,index3) in formData.PHL" :key="index3">
								<FormItem :label="'联系电话'+(index3+1)">
									<Input v-model="formData.PHL[index3].phone" maxlength="11">
										<Button slot="append" @click="delTel(index3)">删除</Button>
									</Input>
								</FormItem>
								<FormItem :label="'联系姓名'+(index3+1)">
									<Input v-model="formData.PHL[index3].name" maxlength="20"></Input>
								</FormItem>
							</div>
							<FormItem label="添加电话">
								<Button type="primary" @click="addBookPbname">添加</Button>
							</FormItem>
						</template>
						<template v-else>
							<FormItem :label="item2" :prop="index2">
								<Input v-model="formData[index2]" maxlength="11"></Input>
							</FormItem>
						</template>
					</template>
					<template v-else-if="item.title == '闹钟设置' || item.title == '时间段设置'">
						<FormItem :label="item2">
							<template v-if="['m1','m2','m3','m4','m5'].indexOf(index2) != -1">
								<RadioGroup v-model="formData[index2]">
									<Radio :label="0" border>关闭</Radio>
									<Radio :label="1" border>开启</Radio>
								</RadioGroup>
							</template>
							<template v-else>
								<TimePicker class="w100" v-model="formData[index2]" :editable="false" format="HH:mm" placeholder="选择时间" transfer />
							</template>
						</FormItem>
					</template>
					<template v-else>
						<FormItem :label="item2" :prop="index2">
							<template v-if="['temperatureAlarmType','sosSmsSwitch','lowBatterySwitch','takeDownSwitch'].indexOf(index2) != -1">
								<RadioGroup v-model="formData[index2]">
									<Radio :label="0" border>关闭</Radio>
									<Radio :label="1" border>开启</Radio>
								</RadioGroup>
							</template>
							<template v-else-if="['temperatureAlarmTel','sos1','sos2','sos3','sosSmsTel'].indexOf(index2) != -1">
								<Input v-model="formData[index2]" maxlength="11"></Input>
							</template>
							<template v-else-if="['temperature','temperatureMin'].indexOf(index2) != -1">
								<InputNumber class="w100" v-model="formData[index2]" :active-change="false" :precision="1" />
							</template>
							<template v-else>
								<InputNumber class="w100" v-model="formData[index2]" :active-change="false" :precision="0" />
							</template>
						</FormItem>
					</template>
				</div>
			</Card>
		</Form>
		<div slot="footer">
			<Button @click="setWatch" type="primary" style="margin-right: 10px;">确定</Button>
			<Button @click="show = false">关闭</Button>
		</div>
	</Modal>
</template>

<script>
	var _this, formData = {
		deviceid: null,
		sos1: '', //紧急联系人号码
		sos2: '', //紧急联系人号码
		sos3: '', //紧急联系人号码
		sosSmsTel: '', // 短信报警号码
		sosSmsSwitch: 1, // sos短信报警开关
		lowBatterySwitch: 1, // 低电短信报警开关
		takeDownSwitch: 1, // 取下手环报警开关
		
		maxheartrate: 100, //最大心率阀值
		minheartrate: 60, //最小心率阀值
		bphighmax: 120, //最大收缩压阀值（高压）
		bphighmin: 90, //最小收缩压阀值（高压）
		bplowmax: 90, //最大舒张压阀值（低压）
		bplowmin: 60, //最小舒张压阀值（低压）
		bloodoxmax: 20, //最大血氧阀值
		bloodoxmin: 5, //最小血氧阀值
		temperature: 38.0, //体温的最高值
		temperatureMin: 36.0, // 体温的最低值
		temperatureAlarmType: 0, // 体温报警类型
		temperatureAlarmTel: '', // 体温报警号码
		
		m1: 0, //开启第一个吃药提醒
		m1edicine: '', //第一个吃药时间
		m2: 0, //开启第二个吃药提醒
		m2edicine: '', //第二个吃药时间
		m3: 0, //开启第三个吃药提醒
		m3edicine: '', //第三个吃药时间
		m4: 0, //开启第四个吃药提醒
		m4edicine: '', //第四个吃药时间
		m5: 0, //开启第五个吃药提醒
		m5edicine: '', //第五个吃药时间
		
		book1: '', //联系人电话号码
		pbname1: '', //联系人姓名
		book2: '', //联系人电话号码
		pbname2: '', //联系人姓名
		book3: '', //联系人电话号码
		pbname3: '', //联系人姓名
		book4: '', //联系人电话号码
		pbname4: '', //联系人姓名
		book5: '', //联系人电话号码
		pbname5: '', //联系人姓名
		
		stepStart1: '', // 计步时间段1 开始时间
		stepEnd1: '', // 计步时间段1 结束时间
		stepStart2: '', // 计步时间段2 开始时间
		stepEnd2: '', // 计步时间段2 结束时间
		stepStart3: '', // 计步时间段3 开始时间
		stepEnd3: '', // 计步时间段3 结束时间
		turnStart1: '', // 翻转检测时间段 开始时间
		turnEnd1: '', // 翻转检测时间段 结束时间
		
		PHL: [{name: '', phone: ''}], // 三基/易赛 设备手机号
	}
	export default {
		data() {
			return {
				path: '/adm', // 区分在机构后台还是商家后台
				show: false,
				deviceid: '', // 设备id
				formItem: [],
				formData: formData, // 数据
				device: {
					m_id: 0, // 设备厂家
					d_type: 0, // 设备类型
				},
				ruleValidate: {
					sos1: [{
						message: '紧急联系人1格式不正确',
						pattern: /^1[3456789]\d{9}$/,
						trigger: 'blur'
					}],
					sos2: [{
						message: '紧急联系人2格式不正确',
						pattern: /^1[3456789]\d{9}$/,
						trigger: 'blur'
					}],
					sos3: [{
						message: '紧急联系人3格式不正确',
						pattern: /^1[3456789]\d{9}$/,
						trigger: 'blur'
					}],
					sosSmsTel: [{
						message: '短信报警号码格式不正确',
						pattern: /^1[3456789]\d{9}$/,
						trigger: 'blur'
					}]
				}
			}
		},
		created() {
			_this = this;
		},
		methods: {
			// 获取 设备控制信息
			open(deviceid) {
				this.formData = JSON.parse(JSON.stringify(formData));
				this.$refs.formValidate.resetFields()
				var data = {
					deviceid: deviceid,
					data: this.formData
				};
				this.requestApi(this.path + '/get_device_settings', data).then(function(res) {
					if (res.status) {
						_this.show = true;
						_this.formData = res.data;
						_this.formData.deviceid = deviceid;
						_this.device = res.device;
						
						// 阈值设置
						var Threshold = {
							maxheartrate: '最大心率阀值', //
							minheartrate: '最小心率阀值', //
							bphighmax: '最大收缩压阀值', //（高压）
							bphighmin: '最小收缩压阀值', //（高压）
							bplowmax: '最大舒张压阀值', //（低压）
							bplowmin: '最小舒张压阀值', //（低压）
							bloodoxmax: '最大血氧阀值', //
							bloodoxmin: '最小血氧阀值', //
							temperature: '体温的最高值', //
							temperatureMin: '体温的最低值', // 
						};
						// 紧急联系人设置
						var Sos = {
							sos1: '紧急联系人1',
							sos2: '紧急联系人2',
							sos3: '紧急联系人3',
						};
						// 联系人设置
						var Linkman = {
							book1: '联系电话1', //联系人电话号码
							pbname1: '联系姓名1', //联系人姓名
							book2: '联系电话2', //联系人电话号码
							pbname2: '联系姓名2', //联系人姓名
							book3: '联系电话3', //联系人电话号码
							pbname3: '联系姓名3', //联系人姓名
							book4: '联系电话4', //联系人电话号码
							pbname4: '联系姓名4', //联系人姓名
							book5: '联系电话5', //联系人电话号码
							pbname5: '联系姓名5', //联系人姓名
						}
						// 闹钟设置
						var AlarmClock = {
							m1: '闹钟开关1',
							m1edicine: '闹钟时间1',
							m2: '闹钟开关2',
							m2edicine: '闹钟时间2',
							m3: '闹钟开关3',
							m3edicine: '闹钟时间3',
						};
						// 时间段设置
						var TimeQuantum = {
							stepStart1: '开始计步时间段1', // 计步时间段1 开始时间
							stepEnd1: '结束计步时间段1', // 计步时间段1 结束时间
							stepStart2: '开始计步时间段2', // 计步时间段2 开始时间
							stepEnd2: '结束计步时间段2', // 计步时间段2 结束时间
							stepStart3: '开始计步时间段3', // 计步时间段3 开始时间
							stepEnd3: '结束计步时间段3', // 计步时间段3 结束时间
							turnStart1: '翻转检测时间段', // 翻转检测时间段 开始时间
							turnEnd1: '翻转检测时间段', // 翻转检测时间段 结束时间
						}
						if(res.device.m_id != 6 && res.device.m_id != 7){
							AlarmClock.m4 = '闹钟开关4';
							AlarmClock.m4edicine = '闹钟时间4';
							AlarmClock.m5 = '闹钟开关5';
							AlarmClock.m5edicine = '闹钟时间5';
							
							for (var key = 1; key <= 5; key++) {
								_this.$set(_this.ruleValidate, 'book' + key, [{
									message: '联系电话' + key + '格式不正确',
									pattern: /^1[3456789]\d{9}$/,
									trigger: 'blur'
								}]);
							}
						}
						// 三基设备
						if(res.device.m_id == 6){
							Threshold.temperatureAlarmType = '体温报警类型';
							Threshold.temperatureAlarmTel = '体温报警号码';
							
							Sos.sosSmsTel = '短信报警号码';
							Sos.sosSmsSwitch = 'sos短信报警开关';
							Sos.lowBatterySwitch = '低电短信报警开关';
							Sos.takeDownSwitch = '取下手环报警开关';
							
							Linkman = { PHL: '' };
						}
						// 易赛设备
						if(res.device.m_id == 7){
							Linkman = { PHL: '' };
						}
						// 联代设备
						if(res.device.m_id == 10){
							Linkman = { PHL: '' };
						}
						var formItem = [];
						if (res.device.m_id == 4 && res.device.d_type == 5) {
							formItem.push({ title: '紧急联系人设置', children: Sos });
						} else {
							formItem.push({ title: '阈值设置', children: Threshold });
							if(res.device.m_id !== 10){
								formItem.push({ title: '紧急联系人设置', children: Sos });
							}
							formItem.push({ title: '联系人设置', children: Linkman });
							if(res.device.m_id !== 10){
								formItem.push({ title: '闹钟设置', children: AlarmClock });
							}
							if (res.device.m_id == 6) {
								formItem.push({ title: '时间段设置', children: TimeQuantum });
							}
						}
						console.log(formItem);
						_this.formItem = formItem;
					}
				});
			},
			// 设置 设备控制信息
			setWatch() {
				var data = JSON.parse(JSON.stringify(this.formData));
				var form_data = {deviceid: data.deviceid};
				for(let index in this.formItem){
					for(let index2 in this.formItem[index].children){
						form_data[index2] = data[index2];
					}
				}
				console.log(form_data);
				this.$refs.formValidate.validate((valid) => {
					if (valid) {
						_this.requestApi(this.path + '/set_device_settings', form_data).then(function(res) {
							if (res.status) {
								_this.alertSucc(res.msg);
								_this.show = false;
							} else {
								_this.alertErr(res.msg)
							}
						});
					} else {
						_this.alertErr('请完善表单后提交!');
					}
				});
			},
			// 添加电话
			addBookPbname() {
				if (this.formData.bookPbname == 30) {
					return this.alertErr('添加已到上限！');
				}
				const data = {name:'',phone:''}
				this.formData.PHL.push(data);
			},
			delTel(index){
				this.formData.PHL.splice(index,1)
			},
		}
	}
</script>

<style scoped>
	.w100 {
		width: 100%;
	}
	.mar-b24 {
		margin-bottom: 24px;
	}
</style>
